import React, { useCallback, useContext } from "react";

const Dashboard = () => {
	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="card-body dashboard-card-body">Dashboard</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
