import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../app/rootReducer";
import { Profile } from "../api/types";

const Sidebar = () => {
	const user = useSelector<RootState>(({ profile }) => profile.profile) as Profile;

	return (
		<aside className="main-sidebar sidebar-dark-primary elevation-4">
			<Link to="/" className="brand-link">
				{/*
				<img
					src="../../dist/img/AdminLTELogo.png"
					alt="AdminLTE Logo"
					className="brand-image img-circle elevation-3"
				/>
				*/}
				<i className="fas fa-receipt nav-icon"></i>&nbsp;
				<span className="brand-text font-weight-light">UlozDoklad Admin {process.env.REACT_APP_STAGE}</span>
			</Link>

			<div className="sidebar">
				<div className="user-panel mt-3 pb-3 mb-3 d-flex">
					{user && (
						<div className="image">
							<img src={user?.image_48 || ""} className="img-circle elevation-2" />
						</div>
					)}
					<div className="info">
						<a href="#" className="d-block">
							{user?.name}
						</a>
					</div>
				</div>

				<nav className="mt-2">
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						data-widget="treeview"
						role="menu"
						data-accordion="false"
					>
						<li className="nav-item">
							<NavLink className="nav-link" to="/" strict exact>
								<i className="nav-icon fas fa-tachometer-alt"></i>
								<p>Dashboard</p>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/users" strict>
								<i className="fas fa-users nav-icon"></i>
								<p>Users</p>
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink className="nav-link" to="/receipts" strict>
								<i className="fas fa-receipt nav-icon"></i>
								<p>Receipts</p>
							</NavLink>
						</li>
					</ul>
				</nav>
			</div>
		</aside>
	);
};

export default Sidebar;
