import React, { FC, PropsWithChildren, useEffect } from "react";
import firebase from "firebase/app";
import { Dispatch } from "redux";
import FirebaseContext from "./firebaseContext";

interface Props {
	firebase: typeof firebase;
	dispatch: Dispatch;
	onAuthStateChanged: (user: firebase.User) => void;
}

type HOCProps = PropsWithChildren<Props>;

const FirebaseProvider: FC<HOCProps> = ({ firebase, dispatch, children, onAuthStateChanged }: HOCProps) => {
	firebase.auth().onAuthStateChanged(async (user) => {
		onAuthStateChanged(user);
	});

	return <FirebaseContext.Provider value={firebase}>{children}</FirebaseContext.Provider>;
};

export default FirebaseProvider;
