import { HttpClient } from "@bazo/fetch-client";
import Cookies from "js-cookie";

const onRequestCreate = async (request: Request): Promise<any> => {
	const accessToken = Cookies.get("accessToken");

	if (accessToken) {
		request.headers.set("Authorization", `Bearer ${accessToken}`);
	}
};

function createHttp(baseURL: string): HttpClient {
	const client = new HttpClient(baseURL);
	client.on(HttpClient.REQUEST_CREATE, onRequestCreate);

	return client;
}

const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/admin`;
export const createApiClient = (): HttpClient => createHttp(baseUrl);
