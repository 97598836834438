import React from "react";
import { RouteChildrenProps, Redirect } from "react-router-dom";
import SlackSignInButton from "../components/slackSignInButton";
import parseSearchQuery from "../libs/parseSearchQuery";
import { getAccessToken } from "../api";
import { useFirebase } from "../libs/firebase/useFirebase";
import { LocationDescriptor } from "history";
import { useSelector } from "react-redux";
import { RootState } from "../app/rootReducer";
interface SearchQuery {
	code: string;
	state: string;
}

const emptyFrom = { hash: "", pathname: "/", search: "", state: undefined } as LocationDescriptor;

interface Props extends RouteChildrenProps<any, { from?: typeof emptyFrom }> {}

const Login = ({ history, match, location }: Props) => {
	const firebase = useFirebase();
	const { isAuthenticated } = useSelector(({ auth }: RootState) => auth);

	if (isAuthenticated) {
		return <Redirect to={location.state.from} />;
	}

	if (location.search) {
		const { code, state } = parseSearchQuery<SearchQuery>(location.search);

		getAccessToken(code, state)
			.then(({ accessToken }) => {
				login(accessToken);
			})
			.catch((err) => {
				console.log({ err });
				document.location.href = "/login";
			});
	}

	const login = async (token: string) => {
		//.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
		try {
			await firebase.auth().signInWithCustomToken(token);
			history.push("/");
		} catch (error) {
			console.log({ error });
			// Handle Errors here.
			const errorCode = error.code;
			const errorMessage = error.message;
			// The email of the user's account used.
			const email = error.email;
			// The firebase.auth.AuthCredential type that was used.
			const credential = error.credential;
		}
	};

	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="card-body login-card-body">
						<p className="login-box-msg">Sign in to start your session</p>

						<div className="social-auth-links text-center mb-3">
							<SlackSignInButton
								clientId={"955033412114.1118207277572"}
								redirectUri={document.location.href}
								state={document.location.href}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
