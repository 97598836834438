import React from "react";

const SlackSignInButton = ({
	clientId,
	redirectUri,
	state,
	team,
	scopes = ["identity.basic", "identity.email", "identity.team", "identity.avatar"],
	alt = "Sign in with Slack",
}: {
	clientId: string;
	redirectUri: string;
	state?: string;
	team?: string;
	scopes?: string[];
	alt?: string;
}) => {
	const url = `https://slack.com/oauth/v2/authorize?user_scope=${scopes.join(
		","
	)}&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(
		state
	)}&team=${team}`;

	return (
		<a href={url}>
			<img
				alt={alt}
				height="40"
				width="172"
				src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
				srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
			/>
		</a>
	);
};

export default SlackSignInButton;
