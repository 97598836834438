import { Store, StoreEnhancer } from "redux";
import {
	persistStore,
	persistReducer,
	Persistor,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { routerMiddleware } from "connected-react-router";
import logger from "redux-logger";
import { History } from "history";

import { configureStore, getDefaultMiddleware, ThunkAction, Action } from "@reduxjs/toolkit";

import createRootReducer, { RootState } from "./rootReducer";
import monitorReducerEnhancer from "../store/enhancers/monitor";
import { loginSuccess } from "../features/auth/authSlice";

const persistConfig = (key: string) => {
	return {
		key,
		storage,
		//whitelist: ["auth", "profile"],
		blacklist: ["auth"],
	};
};

//export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default (history: History): { store: Store<RootState>; persistor: Persistor } => {
	let middleware = [routerMiddleware(history)];

	if (process.env.NODE_ENV === "development") {
		middleware.push(logger);
	}

	const rootReducer = createRootReducer(history);
	const persistedReducer = persistReducer(persistConfig("root"), rootReducer);

	const store = configureStore({
		reducer: persistedReducer,
		middleware: [
			...getDefaultMiddleware({
				immutableCheck: false,
				serializableCheck: {
					ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, loginSuccess.toString()],
				},
			}),
			...middleware,
		],
		enhancers: (defaultEnhancers) => [monitorReducerEnhancer as StoreEnhancer, ...defaultEnhancers],
	});

	//store.dispatch(verifyAuth());

	let persistor = persistStore(store);
	return { store, persistor };
};
