import { createApiClient } from "../libs/createHttp";
import { TokenResponse, User, ProfileResponse, Receipt } from "./types";

const http = createApiClient();

export async function getAccessToken(code: string, redirectUri: string): Promise<TokenResponse> {
	return http.get<TokenResponse>(`/token?code=${code}&redirectUri=${redirectUri}`);
}

export async function getProfile(): Promise<ProfileResponse> {
	return http.get<ProfileResponse>("/profile");
}

export async function getUsers() {
	const res = await http.get<User[]>("/users");
	return res;
}

export async function getReceipts(filter?: Partial<Receipt>) {
	const params = filter ? new URLSearchParams(filter as Record<string, string>) : null;
	const res = await http.get<Receipt[]>(`/receipts${params ? `?${params}` : ""}`);
	return res;
}
