import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
	isLoggingIn: true,
	isLoggingOut: false,
	isVerifying: true,
	loginError: false,
	logoutError: false,
	isAuthenticated: false,
	user: {} as firebase.User,
};

export type AuthState = typeof initialState;

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		loginSuccess(state, action: PayloadAction<{ user: firebase.User }>) {
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: true,
				user: action.payload.user,
			};
		},
		loginFailure(state, action: PayloadAction<any>) {
			return {
				...state,
				isLoggingIn: false,
				isAuthenticated: false,
				loginError: true,
			};
		},
		verifyRequest(state, action: PayloadAction<any>) {
			return {
				...state,
				isVerifying: true,
				verifyingError: false,
			};
		},
		verifySuccess(state, action: PayloadAction<any>) {
			return {
				...state,
				isVerifying: false,
			};
		},
	},
});

export const { loginFailure, loginSuccess, verifyRequest, verifySuccess } = authSlice.actions;

export default authSlice.reducer;
