import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../app/createStore";
import { getProfile } from "../../api";
import { Profile, ProfileResponse, Team } from "../../api/types";

const initialState = {
	profile: null as Profile,
	team: null as Team,
	loading: false,
	error: null,
};

export type AuthState = typeof initialState;

const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		fetchProfileStart(state) {
			state.loading = true;
			state.error = null;
		},
		fetchProfileSuccess(
			state,
			{
				payload: {
					res: { ok, team, user },
				},
			}: PayloadAction<{ res: ProfileResponse }>
		) {
			state.loading = false;
			state.profile = user;
			state.team = team;
		},
		fetchProfileError(state, { payload }: PayloadAction<string>) {
			state.loading = false;
			state.error = payload;
		},
	},
});

export const { fetchProfileStart, fetchProfileSuccess, fetchProfileError } = profileSlice.actions;

export default profileSlice.reducer;

export const fetchProfile = (): AppThunk => async (dispatch) => {
	try {
		dispatch(fetchProfileStart);
		const res = await getProfile();
		dispatch(fetchProfileSuccess({ res }));
	} catch (err) {
		dispatch(fetchProfileError(err));
	}
};
