import React from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";

const Layout = ({ children }: { children: React.ReactNode }) => {
	return (
		<div className="wrapper">
			<Navbar />
			<Sidebar />
			<div className="content-wrapper">
				{/*
				<section className="content-header">
					<div className="container-fluid">
						<div className="row mb-2">
							<div className="col-sm-6">
								<h1>Fixed Layout</h1>
							</div>
							<div className="col-sm-6">
								<ol className="breadcrumb float-sm-right">
									<li className="breadcrumb-item">
										<a href="#">Home</a>
									</li>
									<li className="breadcrumb-item">
										<a href="#">Layout</a>
									</li>
									<li className="breadcrumb-item active">
										Fixed Layout
									</li>
								</ol>
							</div>
						</div>
					</div>
				</section>
				*/}

				{/*
				<section className="content">
					<div className="container-fluid">{children}</div>
				</section>
				*/}

				{children}
			</div>

			<footer className="main-footer">
				<div className="float-right d-none d-sm-block">
					<b>Version</b> {process.env.REACT_APP_GIT_SHA}
				</div>
			</footer>

			<aside className="control-sidebar control-sidebar-dark"></aside>
		</div>
	);
};

export default Layout;
