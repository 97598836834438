import React from "react";

import { Switch, Route } from "react-router-dom";
import Login from "../pages/login";
import WithLayout from "./withLayout";
import { PrivateRoute } from "./privateRoute";
import Dashboard from "../pages/dashboard";
import Users from "../pages/users";
import Receipts from "../pages/receipts";

const Routes = () => {
	return (
		<Switch>
			<Route
				path="/login"
				render={(props) => {
					return <Login {...{ ...props }} />;
				}}
			/>

			<WithLayout path="/" exact component={PrivateRoute(Dashboard)} />
			<WithLayout path="/users" exact component={PrivateRoute(Users)} />
			<WithLayout path="/receipts" exact component={PrivateRoute(Receipts)} />
		</Switch>
	);
};

export default Routes;
