import React, { FC, useEffect, useState, ReactNode, forwardRef, useImperativeHandle } from "react";
import { parseISO, format } from "date-fns";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loader";
import { DataGrid, CellRenderer } from "@bazo/datagrid";
import { getReceipts } from "../../api";
import { Receipt } from "../../api/types";
import ReceiptsFilter from "./receiptsFilter";
interface Props {
	//onDelete: Function;
}

const dateRenderer: CellRenderer = (dateString, row, key) => {
	const date = parseISO(dateString);
	return <span title={dateString}>{format(date, "dd.MM.yyyy HH:mm:ss")}</span>;
};

const ReceiptsTable = ({}: Props) => {
	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState<Receipt[]>([]);
	const [filter, setFilter] = useState({});

	const load = async (callback?: Function) => {
		const receipts = await getReceipts(filter);
		setData(receipts);
		//setData(lists.sort(dateSorter).reverse());
		setLoading(false);
		if (callback) {
			//callback(lists);
		}
	};

	useEffect(() => {
		load();
	}, [filter]);

	const reload = () => {
		setLoading(true);
		load();
	};

	const onFilterChange = (filter) => {
		setFilter(filter);
	};

	return isLoading ? (
		<Loader />
	) : (
		<>
			<ReceiptsFilter data={data} onChange={onFilterChange} />
			<DataGrid
				data={data}
				columns={{
					ID: "ID",
					userID: "UserID",
					issueDate: "Issue Date",
					scannedAt: "Scan Date",
					savedAt: "Saved Date",
					totalPrice: "Total",
					items: "Items count",
				}}
				renderers={{
					issueDate: dateRenderer,
					scannedAt: dateRenderer,
					savedAt: dateRenderer,
					items: (items) => items.length,
				}}
			/>
		</>
	);
};

export default ReceiptsTable;
