import React, { FC } from "react";

import { History } from "history";
import { Route, Redirect } from "react-router";
import { RootState } from "../app/rootReducer";

import { useSelector } from "react-redux";
import Loader from "./loader";

type Props = {
	authenticated: boolean;
	history: History;
	tokenExpires: number;
	match: any;
};

export function PrivateRoute(ComposedComponent: React.ComponentType) {
	const { isAuthenticated, isLoggingIn } = useSelector(({ auth }: RootState) => auth);

	return ({ ...props }) => (
		<Route
			{...props}
			render={({ location }) => {
				/*
				if (isLoggingIn) {
					return <Loader />;
				}
				*/
				if (isAuthenticated) {
					return <ComposedComponent {...props} />;
				}

				return (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: location },
						}}
					/>
				);
			}}
		/>
	);
}

/*
export function PrivateRoute(ComposedComponent: React.ComponentType) {
	const Secured = ({ isAuthenticated, ...props }: { isAuthenticated: boolean }) => {
		return (
			<Route
				{...props}
				render={({ location }) =>
					isAuthenticated ? (
						<ComposedComponent {...props} />
					) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location },
							}}
						/>
					)
				}
			/>
		);
	};
	return withRedux({}, mapStateToProps)(Secured);
}
*/
