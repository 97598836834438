type ReturnType<T> = { [K in keyof T]: string };

export default function parseSearchQuery<T extends {}>(search: string): ReturnType<T> {
	const pairs = search.substr(1).split("&");

	return pairs.reduce((prev, pair) => {
		const [key, value] = pair.split("=");
		return { ...prev, [key]: value };
	}, {} as ReturnType<T>);
}
