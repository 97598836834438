import React from "react";
import ReceiptsTable from "../features/receipts/receiptsTable";

const Receipts = () => {
	return (
		<div className="row">
			<div className="col-12">
				<div className="card">
					<div className="card-body dashboard-card-body">
						<h1>Receipts</h1>
					</div>
					<ReceiptsTable />
				</div>
			</div>
		</div>
	);
};

export default Receipts;
