import React, { FC, useEffect, useState, ReactNode, forwardRef, useImperativeHandle } from "react";
import { parseISO, format } from "date-fns";
import { NavLink } from "react-router-dom";
import Loader from "../../components/loader";
import { DataGrid } from "@bazo/datagrid";
import { getUsers } from "../../api";
import { User } from "../../api/types";
interface Props {
	//onDelete: Function;
}

const UsersTable = ({}: Props) => {
	const [isLoading, setLoading] = useState(true);
	const [data, setData] = useState<User[]>([]);

	const load = async (callback?: Function) => {
		const users = await getUsers();
		setData(users);
		//setData(lists.sort(dateSorter).reverse());
		setLoading(false);
		if (callback) {
			//callback(lists);
		}
	};

	useEffect(() => {
		load();
	}, []);

	const reload = () => {
		setLoading(true);
		load();
	};

	return isLoading ? (
		<Loader />
	) : (
		<DataGrid
			data={data}
			columns={{
				ID: "ID",
				email: "Email",
				totalReceiptsCount: "Receipts",
				totalReceiptsSum: "Sum",
				createdAt: "Created",
			}}
		/>
	);
};

export default UsersTable;
