import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { PersistGate } from "redux-persist/integration/react";
import Cookies from "js-cookie";
import "./styles/app.scss";
import "@fortawesome/fontawesome-free/css/all.css";

import App from "./app/app";
import createStore from "./app/createStore";
import FirebaseProvider from "./libs/firebase/firebaseProvider";

import * as serviceWorker from "./serviceWorker";
import { loginSuccess } from "./features/auth/authSlice";

const firebase = window.firebase;

const history = createBrowserHistory({
	basename: process.env.REACT_APP_BASE_PATH,
});
const { store, persistor } = createStore(history);

const onAuthStateChanged = async (user: firebase.User) => {
	if (user !== null) {
		const accessToken = await user.getIdToken();
		Cookies.set("accessToken", accessToken);
		store.dispatch(loginSuccess({ user }));
	}
};

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<FirebaseProvider firebase={firebase} dispatch={store.dispatch} onAuthStateChanged={onAuthStateChanged}>
				<App />
			</FirebaseProvider>
			,
		</PersistGate>
	</Provider>,

	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
