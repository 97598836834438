import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "../components/routes";
import UserContext from "../contexts/userContext";
import { RootState } from "./rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfile } from "../features/profile/profileSlice";

const App = () => {
	const { user, isAuthenticated } = useSelector(({ auth }: RootState) => auth);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user && isAuthenticated) {
			dispatch(fetchProfile());
		}
	}, [user, isAuthenticated]);

	return (
		<UserContext.Provider value={user}>
			<Router>
				<Routes />
			</Router>
		</UserContext.Provider>
	);
};

export default App;
