import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import auth from "../features/auth/authSlice";
import profile from "../features/profile/profileSlice";

export type RootReducer = typeof rootReducer;
const rootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		profile,
	});

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
