import React from "react";
import "../styles/loader.scss";

function Loader({ text, loading = true, notext = false }: { text?: string; loading?: boolean; notext?: boolean }) {
	if (!loading) {
		return null;
	}

	return (
		<>
			<div className="loading">
				<div className="loader">
					<div className="lds-ring">
						<div />
						<div />
						<div />
						<div />
					</div>
					{!notext ? <div className="label">{typeof text === "string" ? text : "Loading..."}</div> : null}
				</div>
			</div>
			<div className="modal-backdrop fade show" />
		</>
	);
}

export default Loader;
