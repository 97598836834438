import React, { ReactNode, useState, useEffect } from "react";
import { Receipt } from "../../api/types";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";
import "react-datepicker/src/stylesheets/datepicker.scss";
import { pickBy, omit } from "ramda";

type FilterField<T = any, K = keyof T> = ({ key, data }: { key: K; data?: T[] }) => ReactNode;
type Filter<T = any, KEYS = keyof T> = ({
	data,
	keys,
	onChange,
}: {
	data?: T[];
	keys?: KEYS;
	onChange: (values: Partial<T> & { [x: string]: any }) => void;
}) => JSX.Element;

const emptyDatePickerState = { from: null as Date, to: null as Date };
type DatePickerState = typeof emptyDatePickerState;

const DatePickerAdapter = ({ input: { onChange, value }, ...rest }) => {
	const [state, setState] = useState(emptyDatePickerState);

	useEffect(() => {
		onChange(state);
	}, [state]);

	const changeFrom = (from: Date) => {
		setState({ ...state, from });
		onChange(state);
	};

	const changeTo = (to: Date) => {
		setState({ ...state, to });
	};

	const popperPlacement = "bottom-start";
	const popperModifiers = {
		offset: {
			enabled: true,
			offset: "5px, 10px",
		},
		preventOverflow: {
			enabled: true,
			escapeWithReference: false,
			boundariesElement: "viewport",
		},
	};

	return (
		<div className="datepicker">
			<DatePicker
				selected={state.from}
				onChange={changeFrom}
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={15}
				timeCaption="time"
				dateFormat="dd.MM.yyyy HH:mm"
				className={`datepicker-field datepicker-from ${rest.className}`}
				startDate={state.from}
				endDate={state.to}
				popperPlacement={popperPlacement}
			/>
			<DatePicker
				selected={state.to}
				onChange={changeTo}
				showTimeSelect
				timeFormat="HH:mm"
				timeIntervals={15}
				timeCaption="time"
				dateFormat="dd.MM.yyyy HH:mm"
				className={`datepicker-field datepicker-to ${rest.className}`}
				minDate={state.from}
				startDate={state.from}
				endDate={state.to}
				popperPlacement={popperPlacement}
			/>
		</div>
	);
};
const ReceiptsFilter: Filter<Receipt> = ({ keys, data, onChange }) => {
	const onSubmit = (values) => {
		const scannedAt = values.scannedAt as DatePickerState;

		const filter = pickBy((val) => val !== null, {
			...omit(["scannedAt"], values),
			scannedTimestampFrom: scannedAt.from ? scannedAt.from.valueOf() / 1000 : null,
			scannedTimestampTo: scannedAt.to ? scannedAt.to.valueOf() / 1000 : null,
		});

		onChange(filter);
	};

	return (
		<div>
			<Form<Partial<Receipt>>
				onSubmit={onSubmit}
				initialValues={{}}
				render={({ handleSubmit, form, submitting, pristine, values }) => (
					<form onSubmit={handleSubmit}>
						<div className="form-row">
							<div className="form-group col-auto">
								<label>ID</label>
								<Field<Receipt["ID"]>
									name="id"
									component="input"
									type="text"
									placeholder="ID"
									className="form-control"
								/>
							</div>
							<div className="form-group col-auto">
								<label>User ID</label>
								<Field<Receipt["userID"]>
									name="userID"
									component="input"
									type="text"
									placeholder="userID"
									className="form-control"
								/>
							</div>
							<div className="form-group col-auto">
								<label>Scanned At</label>
								<Field<Receipt["scannedAt"]>
									name="scannedAt"
									component={DatePickerAdapter}
									className="form-control"
								/>
							</div>
							<div className="form-group col-auto">
								<br />
								<button
									type="submit"
									className="btn btn-primary form-control mt-2"
									disabled={submitting || pristine}
								>
									<i className="fas fa-search"></i>
								</button>
							</div>
						</div>
					</form>
				)}
			/>
		</div>
	);
};

export default ReceiptsFilter;
